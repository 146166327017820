import {
  CART_NUMBER_ADD_TO_CART,
  CART_NUMBER_INCREMENT_QTY,
  CART_NUMBER_DECREMENT_QTY,
  CART_NUMBER_DELECT
} from "../constants/ActionTypes";


export default function cartNumberReducer(state = {
  cart: 0
}, action) {
  switch (action.type) {
    case CART_NUMBER_ADD_TO_CART:
      return { cart: action.value }
    case CART_NUMBER_INCREMENT_QTY:
      return { cart: state.cart++ }

    case CART_NUMBER_DECREMENT_QTY:
      return { cart: state.cart-- }
    case CART_NUMBER_DELECT:
      return { cart: 0 }
    default:
  }

  return state;
}
