import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";
import './header-one.css'
// Import custom components
import { Badge } from 'antd';
import store from "../../../store";
import NavBar from "./common/navbar";
import LogoImage from "./common/logo";
import { purchaseNowDelect } from "@/actions";
import { connect } from "react-redux";
import {
  getToken, getUserName
} from "@/storage/LocalParamers";
import {
  UserOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons';
import {
  getJumpLoginPage,
  putJumpLoginPage
} from '@/storage/SessionParamers'
import intl from "react-intl-universal";
const myLogo = require("@/assets/images/portfolio/my.png");
const shop = require("@/assets/images/portfolio/shop.png");

class HeaderOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      shopCarNum: 0,
      showCartStatus: false,
      showMaOrderStatus: false,
    };
  }
  /*=====================
         Pre loader
         ==========================*/

  componentDidMount() {

    if (document.querySelector(".loader-wrapper")) {
      setTimeout(function () {
        document.querySelector(".loader-wrapper").style = "display: none";
      }, 1000);
    }

    let showMaOrderList = window.location.href;
    if (showMaOrderList.includes("personal-centre") || showMaOrderList.includes("login")) {
      this.setState({ showMaOrderStatus: true });
    }

    let showCartList = window.location.href;
    if (showCartList.includes("cart")) {
      this.setState({ showCartStatus: true });
    }
    this.setState({ open: true });
    window.addEventListener("scroll", this.handleScroll);

  }



  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 300) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };
  // getDerivedStateFromProps(newProps) {
  //   let showMaOrderList = window.location.href;
  //   if (showMaOrderList.includes("personal-centre") || showMaOrderList.includes("login")) {
  //     this.setState({ showMaOrderStatus: true });
  //   }

  //   let showCartList = window.location.href;
  //   if (showCartList.includes("cart")) {
  //     this.setState({ showCartStatus: true });
  //   }
  // }




  render() {
    const urlParams = new URL(window.location.href);
    let showStatus = false;
    if (urlParams.pathname == "/") {
      showStatus = true;
    }


    const { cartNumbers } = this.props;
    const { showCartStatus, showMaOrderStatus } = this.state
    return (
      <div >
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu" style={{ height: "64px" }}>
                  <div className="menu-left">
                    <div className="brand-logo">
                      <LogoImage logo={this.props.logoName} />
                    </div>
                    <div style={{ marginLeft: "40px" }}>
                      <NavBar />
                    </div>
                  </div>
                  <div
                    className="menu-right pull-right"
                  >
                    {/*Top Navigation Bar Component*/}
                    <div className="header_right_logo">
                      <a
                        onClick={() => getToken() ? "" : putJumpLoginPage(window.location.pathname)}
                        href={getToken() ? `${process.env.PUBLIC_URL}/personal-centre` : `${process.env.PUBLIC_URL}/pages/login`}
                        className={showMaOrderStatus ? "choose_info_clients_icon header_icon_title" : "info_clients_icon header_icon_title"}>
                        {/* <img src={myLogo} alt="" /> */}
                        <div className={showMaOrderStatus ? "select-UserName" : "UserName"}>{getToken() ? getUserName() : intl.get("loginHeader")}</div>
                        <UserOutlined style={{ fontSize: '40px', padding: "10px" }} />
                      </a>
                      <Badge offset={[-15, 15]} overflowCount={99} count={getToken() ? cartNumbers : 0}>
                        <a
                          href={`${process.env.PUBLIC_URL}/cart`}
                          onClick={() => this.props.purchaseNowDelect()}
                          className={showCartStatus ? "select_header_icon_title" : "header_icon_title"}
                        >
                          <div className={showCartStatus ? "select-UserName" : "UserName"}>{intl.get("shoppingCart")}</div>
                          <ShoppingCartOutlined style={{ fontSize: '40px', padding: "10px" }} />
                        </a>
                      </Badge>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    cartNumbers: state.cartNumber.cart,
    key: props.location
  };
};
export default connect(mapStateToProps, { purchaseNowDelect })(HeaderOne);
