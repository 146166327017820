/*
   Application 全局数据，关闭浏览器后自动清空
*/


// export function getCurCode() {
//   let curCode = sessionStorage.getItem("curCode");
//   return JSON.parse(curCode);
// }
//
// export function putCurCode(curCode) {
//   sessionStorage.setItem("curCode", JSON.stringify(curCode));
// }

export function getOwnerKeyId() {
  //正式环境
  return '4556e0faebe56bc8e6ac5a1e8b56f807'
  //cs环境
  //return "46d92a2226b426f9464637aa10f29bfb";
}

export function getMainStoreKeyId() {
  //门店所属的仓库
  //return sessionStorage.getItem("mainStoreKeyId");
  //正式环境
  return '9f89b75b793a6a7956218a91036b60cf';

  //cs环境
  //return '801808eeb824735400dd623c70d8ee23'
}

export function putOwnerKeyId(ownerKeyId) {
  sessionStorage.setItem("ownerKeyId", ownerKeyId);
}

export function getStoreKeyId() {
  //门店
  return sessionStorage.getItem("storeKeyId");
}

export function putStoreKeyId(storeKeyId) {
  sessionStorage.setItem("storeKeyId", storeKeyId);
}



export function putMainStoreKeyId(mainStoreKeyId) {
  sessionStorage.setItem("mainStoreKeyId", mainStoreKeyId);
}

export function getMainStoreName() {
  //门店所属的仓库
  return sessionStorage.getItem("mainStoreName");
}

export function putMainStoreName(mainStoreName) {
  sessionStorage.setItem("mainStoreName", mainStoreName);
}

export function getCurKeyId() {
  return sessionStorage.getItem("curKeyId");
}

export function putCurKeyId(curKeyId) {
  sessionStorage.setItem("curKeyId", curKeyId);
}

export function getStoreName() {
  return sessionStorage.getItem("storeName");
}

export function putStoreName(storeName) {
  sessionStorage.setItem("storeName", storeName);
}

export function getMemberName() {
  return sessionStorage.getItem("memberName");
}

export function putMemberName(memberName) {
  sessionStorage.setItem("memberName", memberName);
}

export function getKeyId() {
  return sessionStorage.getItem("keyId");
}

export function putKeyId(keyId) {
  sessionStorage.setItem("keyId", keyId);
}

export function getMemberType() {
  return sessionStorage.getItem("memberType");
}

export function setMemberType(memberType) {
  sessionStorage.setItem("memberType", memberType);
}
export function getStoreType() {
  return sessionStorage.getItem("storeType");
}

export function putStoreType(storeType) {
  sessionStorage.setItem("storeType", storeType);
}

export function getExamineState() {
  return sessionStorage.getItem("examineState");
}
export function putExamineState(examineState) {
  sessionStorage.setItem("examineState", examineState);
}

export function getSelectGoods() {
  let selectGoods = sessionStorage.getItem("selectGoods");
  return JSON.parse(selectGoods)
}

export function putSelectGoods(selectGoods) {
  if (selectGoods) {
    sessionStorage.setItem("selectGoods", JSON.stringify(selectGoods));
  }
}

export function getJumpLoginPage() {
  let jumpLoginPage = sessionStorage.getItem("jumpLoginPage");
  return JSON.parse(jumpLoginPage)
}

export function putJumpLoginPage(jumpLoginPage) {
  if (jumpLoginPage) {
    sessionStorage.setItem("jumpLoginPage", JSON.stringify(jumpLoginPage));
  }
}


export function getShoppingCartSelected() {
  let shoppingCartSelected = sessionStorage.getItem("shoppingCartSelected");
  return JSON.parse(shoppingCartSelected)
}

export function putShoppingCartSelected(shoppingCartSelected) {
  if (shoppingCartSelected) {
    sessionStorage.setItem("shoppingCartSelected", JSON.stringify(shoppingCartSelected));
  }
}
