import { Link } from "react-router-dom";
import React, { Component } from "react";
import Slider from "react-slick";
import Modal from "react-responsive-modal";
import "./details-price.css";
import { getToken } from "@/storage/LocalParamers";
import { toast } from "react-toastify";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { putJumpLoginPage } from '@/storage/SessionParamers'
import { wishlistedProducts, listUserGoods } from "@/utils/user";

const errorPhoto = require('@/images/errorPhoto.png')
class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);
    loadLocales('products')
    this.state = {
      open: false,
      quantity: 0,
      nav3: null,
      selectClassify: [],
      selectList: [],
      list: [],
      listTags: [],
      wishlistedProductsState: false
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.props.onRef(this)
    this.setState({
      nav3: this.slider3
    });

    // this.changeQty()
  }


  detailInfo = () => {
    const { selectDetail } = this.props;
    let list = [];
    let listTags = [];
    let num = 0;
    let selectList = [];
    const propValuList = selectDetail.propValuList;
    for (let key in propValuList) {
      list.push(key);
      let newListTags = propValuList[key];
      for (let i in newListTags) {
        if (selectDetail.properties.includes(newListTags[i].keyId)) {
          // newListTags[i].index = i;
          newListTags[i].canChoose = true;
          selectList[num] = newListTags[i].keyId
        }
        newListTags[i].index = num;
        listTags.push(newListTags[i]);
      }
      num++;
    }

    this.setState({ list, listTags, selectList })
  }

  cartModifyQty = (val) => {
    this.setState({ quantity: val });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    const { inventoryData } = this.props;

    if (inventoryData && (inventoryData.totalQty > this.state.quantity)) {
      this.setState({ quantity: this.state.quantity + 1 });
    }
  };
  changeQty = e => {
    const { inventoryData } = this.props;
    if (e && e.target.value > 0) {
      if (inventoryData.totalQty >= e.target.value) {
        this.setState({ quantity: parseInt(e.target.value) });
      } else {
        if (inventoryData.totalQty > 0) {
          this.setState({ quantity: parseInt(inventoryData.totalQty) });
        } else {
          this.setState({ quantity: parseInt(0) });
        }
      }
    } else {
      if (inventoryData.totalQty >= 1) {
        this.setState({ quantity: parseInt(1) });
      } else {
        this.setState({ quantity: parseInt(0) });
      }
    }
  };


  propGoodsKeyIdList(vals, newSelectDetail, index) {
    const { selectDetail } = this.props;
    let list = []
    for (const listKey in selectDetail.propGoodsKeyIdList) {
      if (listKey.includes(vals.keyId)) {
        list.push(listKey)
      }
    }

    for (const propValuListVal in newSelectDetail.propValuList) {
      for (const val of newSelectDetail.propValuList[propValuListVal]) {
        val.canChoose = false
        for (const listVal of list) {
          if (listVal.includes(val.keyId)) {
            val.canChoose = true
          }
        }
        if (index == 0 && vals.propNameKeyId == val.propNameKeyId) {
          val.canChoose = true
        }
      }
    }
    return newSelectDetail
  }

  onTagButton(value, indexs) {
    const { selectDetail } = this.props;
    const { selectList, listTags } = this.state;
    let newList = selectList;
    let newSelectList = []
    let newSelectList2 = []
    let itemA = []
    let itemB = []
    let itemC = []

    if (newList[indexs] == value.keyId) {
      newList[indexs] = undefined
    } else {
      newList[indexs] = value.keyId
    }
    let num = 0;
    for (const newListAVal of newList) {
      if (newListAVal) {
        num++
      }
    }

    if (selectDetail.propGoodsKeyIdList) {
      for (const key in selectDetail.propGoodsKeyIdList) {
        for (const newListAVal of newList) {
          if (newListAVal && key.includes(newListAVal)) {
            const items = key.split(',');
            for (const itemsA of items) {
              // itemB.push(itemsA)
              if (itemC.length > 0) {
                if (!itemC.some(item => item == itemsA)) {
                  itemC.push(itemsA)
                }
              } else {
                itemC.push(itemsA)
              }
            }
          }
        }

      }
    }
    let num1Index = -1;
    for (const key in newList) {
      if (newList[key]) {
        num1Index = key
      }
    }
    for (let listTagsCVal of listTags) {
      if (num == 1 && num1Index == listTagsCVal.index) {

        listTagsCVal.canChoose = true
      } else if (num > 0) {
        listTagsCVal.canChoose = false
        for (const itemCVal of itemC) {
          if (listTagsCVal.keyId == itemCVal) {
            listTagsCVal.canChoose = true
          }
        }
        if (listTagsCVal.index == 0) {
          listTagsCVal.canChoose = true
        }
      } else {
        listTagsCVal.canChoose = true
      }
    }

    if (newList.length == Object.keys(selectDetail.propValuList).length && newList.find(item => item !== undefined)) {
      let numB = 0;
      for (const key in selectDetail.propGoodsKeyIdList) {
        let numA = 0;
        for (const newListVla of newList) {
          if (key.includes(newListVla)) {
            numA++;
          }
          if (numA == Object.keys(selectDetail.propValuList).length) {
            numB++;
            this.props.getDetail(selectDetail.propGoodsKeyIdList[key])
            break
          }
        }
      }
      if (numB == 0 && num == Object.keys(selectDetail.propValuList).length) {
        if (selectDetail.propGoodsKeyIdList) {
          for (const key in selectDetail.propGoodsKeyIdList) {
            if (key.includes(value.keyId)) {
              const items = key.split(',');
              for (const itemsA of items) {
                if (itemB.length > 0) {
                  if (!itemB.some(item => item == itemsA)) {
                    itemB.push(itemsA)
                  }
                } else {
                  itemB.push(itemsA)
                }
              }
            }

          }
        }
        for (let newListCIndex in newList) {
          if (newListCIndex > 0 && itemB.some(i => i !== newList[newListCIndex])) {
            newList[newListCIndex] = undefined
          }

        }
        for (let listTagsCVal of listTags) {
          if (num > 0) {
            listTagsCVal.canChoose = false
            for (const itemBVal of itemB) {
              if (listTagsCVal.keyId == itemBVal) {
                listTagsCVal.canChoose = true
              }
            }
            if (listTagsCVal.index == 0) {
              listTagsCVal.canChoose = true
            }
          }
        }
      }
    }

    // let itemNumA = 0;
    // for (const itemAVal of itemA) {
    //   for (const newListVal of newList) {
    //     if (itemAVal == newListVal) {
    //       itemNumA++
    //     }
    //   }
    // }
    // if (itemNumA == 0) {
    //   for (const key in newList) {
    //     if (key !== indexs) {
    //       newList[key] = undefined
    //     }
    //   }
    // }

    // for (const listTagsItemA of listTags) {
    //   if (listTagsItemA.index == 0 || listTagsItemA.keyId == value.keyId) {
    //     listTagsItemA.canChoose = true
    //   } else {
    //     listTagsItemA.canChoose = false
    //     for (const itemAVal of itemA) {
    //       if (itemAVal == listTagsItemA.keyId) {
    //         listTagsItemA.canChoose = true

    //       }
    //     }
    //   }
    // }

    // if (newList[indexs] && newList[indexs] == value.keyId) {
    //   newList[indexs] = undefined
    //   // for (const listTagsItemA of listTags) {
    //   //   if (listTagsItemA.index == indexs) {
    //   //     listTagsItemA.canChoose = true
    //   //   }
    //   // }
    // } else {
    //   newList[indexs] = value.keyId
    // }

    // if (newList.length == Object.keys(selectDetail.propValuList).length && newList.find(item => item !== undefined)) {
    //   for (const key in selectDetail.propGoodsKeyIdList) {
    //     let num = 0
    //     for (const newListVla of newList) {
    //       if (key.includes(newListVla)) {
    //         num++;
    //       }
    //       if (num == Object.keys(selectDetail.propValuList).length) {
    //         this.props.getDetail(selectDetail.propGoodsKeyIdList[key])
    //         break
    //       }
    //     }
    //   }
    // }







    // if (selectDetail.propGoodsKeyIdList) {
    //   for (const key in selectDetail.propGoodsKeyIdList) {
    //     if (key.includes(value.keyId)) {
    //       const items = key.split(',');
    //       newSelectList.push(items)
    //     }
    //   }
    // }

    // for (let index = 0; index < listTags.length; index++) {
    //   let element = listTags[index];

    //   if (element.index == 0 && element.relatedProduct) {
    //     element.canChoose = true

    //   } else if (element.index !== 0 && element.relatedProduct) {
    //     newSelectList2.push(element.keyId)
    //     element.canChoose = false
    //     if (newSelectList.some(item => item.includes(element.keyId))) {
    //       element.canChoose = true
    //     }
    //   }
    // }

    // if (newList[indexs] && newList[indexs] == value.keyId) {
    //   newList[indexs] = undefined

    // } else {
    //   newList[indexs] = value.keyId
    // }
    // if (newList.length == Object.keys(selectDetail.propValuList).length && newList.find(item => item !== undefined)) {
    //   for (const key in selectDetail.propGoodsKeyIdList) {
    //     let num = 0
    //     for (const newListVla of newList) {
    //       if (key.includes(newListVla)) {
    //         num++;
    //       }
    //       if (num == Object.keys(selectDetail.propValuList).length) {
    //         this.props.getDetail(selectDetail.propGoodsKeyIdList[key])
    //         break
    //       }
    //     }
    //   }
    // }
    // let newListNum = 0

    // for (const newListVal of newList) {
    //   if (!newListVal || newListVal == "") {
    //     newListNum++
    //   }

    // }
    // if (newListNum == newList.length) {
    //   for (const listTagsVal of listTags) {
    //     if (listTagsVal.relatedProduct) {
    //       listTagsVal.canChoose = true
    //     }

    //   }
    // }
    this.setState({ selectList: newList, listTags })

  }
  inventoryDataUpdata() {
    const { inventoryData } = this.props;

    if (inventoryData.totalQty <= 0) {
      this.setState({ quantity: 0 });
    } else {
      if (inventoryData.totalQty > 0) {
        if (inventoryData.totalQty < this.state.quantity) {
          this.setState({ quantity: inventoryData.totalQty });
        } else {
          this.setState({ quantity: 1 });
        }
      }
    }

  }
  productTags() {
    const { list, listTags, selectList } = this.state;

    return (
      <div>
        {list.map((item, index) => (
          <div className="tag-title" role="document" key={index}>
            <div className="modal-title">{item} </div>

            {listTags.map(value => {
              if (value.index == index && value.relatedProduct) {
                return (
                  <button
                    key={value.keyId}
                    type="button"
                    className={
                      !value.canChoose ? 'tagGreyButton'
                        :
                        selectList[index] == value.keyId
                          ? "tagButtonActive"
                          : "tagButton"
                    }
                    onClick={e => !value.canChoose ? null : this.onTagButton(value, index)}
                  >
                    {value.title}
                  </button>
                  // <button
                  //   key={value.keyId}
                  //   type="button"
                  //   className={
                  //     selectList[index] == value.keyId
                  //       ? "tagButtonActive"
                  //       : "tagButton"
                  //   }
                  //   onClick={e =>
                  //     this.onTagButton(value, index)}
                  // >
                  //   {value.title}
                  // </button>
                );
              }
            })}
          </div>
        ))
        }
      </div>
    );
  }
  onOpenConfirmModal = () => {
    this.setState({ modal: true });
  };

  onCloseConfirmModal = () => {
    this.setState({ modal: false });
  };
  onAddToCart = () => {
    const { selectDetail } = this.props;
    const { selectList } = this.state

    let newPropValuList = [];
    for (let i in selectDetail.propValuList) {
      newPropValuList.push(i);
    }
    // for (let a in newPropValuList) {


    //   if (!selectList[a]) {
    //     toast.error(intl.get("PleaseSelect") + `${newPropValuList[a]}!`);
    //     return;
    //   }
    // }
    if (!getToken()) {
      this.onOpenConfirmModal();
    } else {
      if (this.props.item != null) {
        let selectDetailNum = 0;
        for (const key in selectDetail.propGoodsKeyIdList) {

          for (const selectListKey of selectList) {
            if (key.includes(selectListKey)) {
              selectDetailNum++;
            }
          }
          if (selectDetailNum == selectList.length) {
            this.props.addToCartClicked(1, selectDetail.propGoodsKeyIdList[key])
            break
          }
        }

        if (selectDetailNum !== selectList.length) {
          toast.error(intl.get("productIsNotForSale"));
        }
        // 
        // clearInterval(this.timeout);
      }
      // this.timeout = setInterval(() => {
      //
      // }, 500);
    }
  };
  onBuynowFun(item, quantity) {

    const { selectDetail } = this.props
    const { selectList } = this.state
    let num = 0;
    for (const vals in selectDetail.propValuList) {
      num++;
    }
    let selectNum = 0;

    for (const val of selectList) {
      if (val) {
        selectNum++;
      }
    }

    if (selectNum === num) {
      this.onWishlistedProductsFu(item)
      // this.props.BuynowClicked(item, quantity)
      // this.props.history.push(`${process.env.PUBLIC_URL}/checkout`)
    } else {
      toast.info(intl.get("PleaseSelectProductAttributes"))
    }

  }
  onWishlistedProductsFu = (item) => {
    wishlistedProducts(item).then(res => {
      if (res.responseCode == "00") {
        toast.success(res.responseMessage)
      }
    })
  }

  render() {
    const {
      symbol,
      item,
      inventoryData,
      selectDetail
    } = this.props;
    var colorsnav = {
      slidesToShow: 6,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true
    };
    let propValuListLength = 0;
    if (selectDetail && selectDetail.propValuList) {
      propValuListLength = Object.keys(selectDetail.propValuList).length
    }
    const { listTagsTitle, selectList, modal } = this.state;

    return (
      <div style={{ width: "350px", marginLeft: "20px" }} className="rtl-text">
        <div className="product-right">
          <div className="product_goodsName"> {item.goodsCode + " " + item.goodsName} </div>
          {getToken() && getToken().indexOf(":5.1.1") == "-1" ? <h3>
            {symbol} {item.specialPrice ? item.specialPrice : item.price}
          </h3> :
            <div className="operation_after_login">
              <Link
                onClick={() => putJumpLoginPage(window.location.pathname)}
                to={`${process.env.PUBLIC_URL}/pages/login`}
              >
                {intl.get("PriceWillBeVisibleAfterLoggingIn")}
              </Link>
            </div>
          }

          <div className="goods_sales">
            <div className="blue_text">{intl.get("newest")}</div>
            {item.specialStatus === 1 ? <div className="red_text">{intl.get("SpecialOffer")}</div> : ""}
          </div>


          <div className="product-description border-product">
            {this.productTags()}
            {propValuListLength > 0 && selectList.length === propValuListLength ? <div >
              <div style={{ marginTop: "20px" }} className="product-title">{intl.get('quantity')}
                <span className="stock_sty">({intl.get("Inventory")} :  {inventoryData && inventoryData.totalQty > 0
                  ? inventoryData.totalQty
                  : 0})</span>

              </div>
              <div className="qty-box">
                <div className="input-group">
                  <span className="input-group-prepend">
                    <button
                      type="button"
                      className="btn quantity-left-minus"
                      onClick={this.minusQty}
                      data-type="minus"
                      data-field=""
                    >
                      <i className="fa fa-angle-left"></i>
                    </button>
                  </span>
                  <input
                    type="number"
                    name="quantity"
                    value={this.state.quantity}
                    onInput={(e) => e.target.value >= 0 ? this.setState({ quantity: e.target.value }) : ""}
                    // onBlur={(e) => this.changeQty(e)}
                    className="form-control input-number input-number-style"
                  />
                  <span className="input-group-prepend">
                    <button
                      type="button"
                      className="btn quantity-right-plus"
                      onClick={this.plusQty}
                      data-type="plus"
                      data-field=""
                    >
                      <i className="fa fa-angle-right"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div> : ""}

          </div>
          {getToken() && getToken().indexOf(":5.1.1") == "-1" ?
            <div>

              <div className="product-buttons">
                <a className="btn btn-solid" onClick={this.onAddToCart}>
                  {intl.get('addtoCart')}
                </a>
                <a
                  className="btn btn-solid"
                  onClick={() => this.onBuynowFun(item, this.state.quantity)}
                >
                  {intl.get("BuyNow")}
                </a>

              </div>
              <a
                className="addto-existingOrder"
                style={{ display: this.props.myOrdersList ? "" : "none", color: "blue" }}
                onClick={() => this.props.openAddProdState()}
              >
                {intl.get("AddtoExistingOrder") + ">"}</a>
            </div>
            :
            <div className="operation_after_login">
              <Link
                onClick={() => putJumpLoginPage(window.location.pathname)}
                to={`${process.env.PUBLIC_URL}/pages/login`}
              >
                {intl.get("AddToCartAfterLoggingIn")}
              </Link>
            </div>
          }
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Sheer Straight Kurta
                </h5>
              </div>
              <div className="modal-body">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`}
                  onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          // ref={modalRef}
          open={modal}
          center={"true"}
          onClose={this.onCloseConfirmModal}
          classNames={{
            modal: "customModal"
          }}
        // initialFocusRef={modalRef}
        >
          <div className="confirmModal">
            <h3>{intl.get("loginrequiredtoaddtocart")}</h3>
            <button
              className="newBtn btn btn-secondary"
              onClick={this.onCloseConfirmModal}
            >
              {intl.get("cancel")}
            </button>
            <Link
              onClick={() => putJumpLoginPage(window.location.pathname)}
              to="/pages/login">
              <button
                className="newBtn btn btn-solid"
              // onClick={() => this.onClickLoginFn()}
              >
                {intl.get("recognise")}
              </button>
            </Link>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DetailsWithPrice;
