import React, { Component } from 'react'
import { InfoCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Input, Form, Table, Spin, DatePicker, Modal } from 'antd'
import {
  myOrders, orderDetail, payQrCode, refreshPayStatus, updateOrder, cancelUnpayorder, getDdressList, getDefaultDdress
} from '@/utils/user'
import OrderDetailModal from '@/components/PersonalCentre/OrderDetailModal'
import { getOwnerKeyId } from "@/storage/SessionParamers";
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import { putUserName, getCurCode } from "@/storage/LocalParamers";
import PayModal from './payModal'
import { toast } from "react-toastify";
import DeliveryAddressModal from './DeliveryAddressModal'

loadLocales('PersonalCentre')
const { confirm } = Modal;
const payType = { 0: intl.get("WeChat"), 1: intl.get("Alipay"), 13: intl.get("SimplePay"), 14: intl.get("CashOnDelivery") }
const payStatus = [intl.get("Unpaid"), intl.get("paid"), intl.get("Cancelled")]
const deliveryStatus = [intl.get("NotAccepted"), intl.get("Shipping"), intl.get("Shipped"), intl.get('Terminated')]
const { RangePicker } = DatePicker;
let pageContext
export default class MyOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myOrdersList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      symbol: getCurCode(),
      orderNo: null,
      startDate: null,
      endDate: null,
      loading: false,
      orderDetailList: false,
      orderDetailModal: false,
      orderDetailModalLoading: false,
      payQRcodeState: false,
      payInfo: [],
      closeModal: false,
      openState: false,
      ddressList: false,
      defaultDdress: false,
      modifyOrderDetailList: false,
      modifyOrderDetailState: false
    }
    this.formRef = React.createRef();
    this.childRef = React.createRef();
    pageContext = this
  }

  componentDidMount() {
    this.getMyOrders();
    this.getDefaultDdress()
  }

  getDefaultDdress() {
    getDefaultDdress().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data) {
          pageContext.setState({ defaultDdress: res.data });
        }
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.getPayQrCodeInterval)
    clearInterval(this.payQrCodeFun)
  }

  getMyOrders = () => {
    const { pagination, orderNo, startDate, endDate } = this.state;

    let params = {
      current: pagination.current,
      size: pagination.pageSize,
      total: pagination.total,
      ownerKeyId: getOwnerKeyId(),
    }
    if (orderNo) {
      params.orderNo = orderNo
    }
    if (startDate) {
      params.startDate = startDate
    }
    if (endDate) {
      params.endDate = endDate
    }

    this.setState({ loading: true });
    myOrders(params).then(res => {
      this.setState({ loading: false });
      if (res && res.responseCode === "00") {
        if (res.data.loginVO) {
          putUserName(res.data.loginVO.userName)
          this.props.userNameFun(res.data.loginVO.userName)
        }
        let newPagination = {
          current: res.data.current,
          pageSize: res.data.size,
          total: res.data.total,
        }
        this.setState({ myOrdersList: res.data.records, pagination: newPagination });
      }
    })
  }

  getOrderDetail(item) {
    const param = { orderKeyId: item.keyId }
    this.setState({ orderDetailModal: true, orderDetailModalLoading: true, });
    orderDetail(param).then(res => {
      if (res && res.responseCode === "00" && res.data) {
        this.setState({ orderDetailList: { ...res.data, item }, modifyOrderDetailList: res.data.orderGoodsVOList });
      } else {
        this.setState({ orderDetailList: false, modifyOrderDetailList: false });
      }
      this.setState({ orderDetailModalLoading: false });
    })
  }

  openPayModal = (item) => {
    const param = { orderKeyId: item.keyId }
    orderDetail(param).then(res => {
      if (res && res.responseCode === "00" && res.data) {
        this.openPayQRcodeState({ ...res.data, item })
        // this.setState({ orderDetailList: { ...res.data, item } });
      } else {
        this.setState({ orderDetailList: false });
      }
    })
  }
  openPayQRcodeState = (values) => {
    this.setState({
      payQRcodeState: true
    }, () => { this.genpay(values) });
    // payInfo
  };

  onChange = (pagination, filters, sorter) => {
    this.setState({ pagination }, () => { this.getMyOrders() })
  }

  switchPayQRcodeState = (values) => {
    const { modifyOrderDetailState } = this.state
    if (modifyOrderDetailState) {
      confirm({
        title: '已對訂單進行修改!是否確認支付?',
        icon: <ExclamationCircleFilled />,
        content: '支付會復原所修改的內容!',
        onOk() {
          pageContext.setState({
            payQRcodeState: true,
            orderDetailModal: false
          }, () => { pageContext.genpay(values.orderDetailList) });
        },

      });
    } else {
      this.setState({
        payQRcodeState: true
      }, () => { this.genpay(values.orderDetailList) });
    }

    // payInfo
  };

  onFinishFailed = (errorInfo) => {
  };

  handleCancel = () => {
    const { modifyOrderDetailState } = this.state
    if (modifyOrderDetailState) {
      confirm({
        title: '已對訂單進行修改!是否確認關閉彈窗?',
        icon: <ExclamationCircleFilled />,
        content: '關閉彈窗會復原所修改的內容!',
        onOk() {
          pageContext.setState({ orderDetailModal: false, modifyOrderDetailState: false });
        },

      });
    } else {
      pageContext.setState({ orderDetailModal: false });

    }
  }

  onChangeInput = (val) => {
    this.setState({ orderNo: val.target.value });
  }

  onChangeRangePicker = (val) => {
    let startDate = null;
    let endDate = null
    if (val) {
      if (val[0]) {
        startDate = val[0].format('YYYY-MM-DD');
      }
      if (val[1]) {
        endDate = val[1].format('YYYY-MM-DD')
      }
    }
    this.setState({
      startDate,
      endDate
    });
  }


  genpay = (value) => {
    clearInterval(this.getPayQrCodeInterval)
    let payInfo = value;
    const payQrCodeInfo = {
      orderKeyId: value.item.keyId,
      orderNo: value.orderNo,
      payAmount: value.amtValue,
    }
    payQrCode(payQrCodeInfo).then(payQrCoderes1 => {

      if (payQrCoderes1 && payQrCoderes1.responseCode == "00") {
        if (payQrCoderes1.data.payQrcode != "") {
          // if (payQrCoderes1.data.payStatus == 0) {
          payInfo.payQrcode = payQrCoderes1.data.payQrcode;
          payInfo.payRecordKeyId = payQrCoderes1.data.payRecordKeyId
          this.setState({ loading: false, payQRcodeState: true, payInfo, payQrCoderes1, payQrCodeIndex: 0 }, () => {
            this.payQrCodeFun = setInterval(() => this.getRefreshPayStatus(value), 5000)
          });

          // }
          // else {
          //   this.setState({ payInfo, })
          // }
        } else {
          payInfo.payRecordKeyId = payQrCoderes1.data.payRecordKeyId;
          this.setState({ payQrCoderes1, payQrCodeIndex: 1, payInfo, loading: true }, () => {
            this.getPayQrCodeInterval = setInterval(() => this.getpayCode(value), 3000)

          })

        }
      } else {
        toast.error(`${payQrCoderes1.responseMessage}!`);
      }
    })

  }

  getpayCode = (value) => {
    const { payInfo } = this.state
    let newPayInfo = payInfo

    payQrCode(payInfo).then(payQrCoderes1 => {

      if (payQrCoderes1 && payQrCoderes1.responseCode == "00") {
        // if (payQrCoderes1.data.payStatus === 0) {
        if (payQrCoderes1.data.payStatus == 2) {
          clearInterval(this.getPayQrCodeInterval)
          toast.info("生成支付码失败!")

          this.onPayQRcodeCancel()
        } else if (payQrCoderes1.data.payQrcode != "") {

          clearInterval(this.getPayQrCodeInterval)
          newPayInfo.payQrcode = payQrCoderes1.data.payQrcode;
          newPayInfo.payRecordKeyId = payQrCoderes1.data.payRecordKeyId
          this.setState({ loading: false, payQRcodeState: true, payInfo: newPayInfo, payQrCoderes1, payQrCodeIndex: 0 }, () => {
            this.payQrCodeFun = setInterval(() => this.getRefreshPayStatus(value), 5000)
          });
        }
      }
    })
  }

  getRefreshPayStatus = (value) => {
    const { placeOrderRes, payQrCoderes1 } = this.state
    if (payQrCoderes1) {
      const refreshPayInfos = {
        orderKeyId: value.item.keyId,
        orderNo: value.orderNo,
        payAmount: value.amtValue,
        payRecordKeyId: payQrCoderes1.data.payRecordKeyId,
      }
      refreshPayStatus(refreshPayInfos).then(payQrCoderes2 => {
        if (payQrCoderes2 && payQrCoderes2.responseCode == "00") {
          if (payQrCoderes2.data.payStatus === "1") {
            clearInterval(this.payQrCodeFun)
            this.props.history.replace(`${process.env.PUBLIC_URL}/pages/order-success`)
          } else if (payQrCoderes2.data.payStatus === "2") {
            toast.error(intl.get("paymentFailure"));
            clearInterval(this.payQrCodeFun)
          }
        } else {
          clearInterval(this.payQrCodeFun)
        }
      })
    }

  }

  onPayQRcodeCancel = (item) => {
    clearInterval(this.getPayQrCodeInterval)
    clearInterval(this.payQrCodeFun)
    this.setState({ payQRcodeState: false })
    // pageContext.props.history.goBack()
    // this.props.history.replace(`${process.env.PUBLIC_URL}/pages/order-success`)

  }
  handleOkClose = () => {
    this.cancelOrderFun()
  }

  onCancelClose = () => {
    this.setState({ closeModal: false });

  }

  cancelOrderFun = () => {
    const { payInfo } = this.state
    pageContext.delectCancelUnpayorder(payInfo);
  }


  delectCancelUnpayorder = (item) => {
    const param = { orderKeyId: item.keyId }
    confirm({
      title: intl.get("OrderCancellationNotification"),
      content: intl.get("DoYouWantCancelOrder"),
      icon: <ExclamationCircleFilled />,
      cancelText: intl.get("back"),
      okText: intl.get("CancelOrder"),
      onOk() {
        pageContext.setState({ payQRcodeState: false, orderDetailModal: false, })

        cancelUnpayorder(param).then(res => {
          if (res && res.responseCode == "00") {
            Modal.success({
              title: intl.get("OrderCancellationNotification"),
              content: '已成功取消訂單',
            });
            pageContext.getMyOrders()
          }
        })
      },

    })

  }

  onUseAnotherAddressHandle = () => {
    getDdressList().then(res => {
      if (res && res.responseCode == "00") {
        const defaultCheckbox = res.data.filter(item => item.isDefault == "1").map(item => item.keyId)
        pageContext.setState({ ddressList: res.data, openState: true });
      }
    })
  }

  onUseNewAddress = () => {
    const { orderDetailList } = this.state
    let orderDet = { ...orderDetailList }
    getDdressList().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data && res.data.length > 0) {
          orderDet.address = res.data[0].recieveAddress
          orderDet.contactName = res.data[0].recieveName
          orderDet.contactTel = res.data[0].phone
          pageContext.setState({ orderDetailList: orderDet, modifyOrderDetailState: true });
        }
      }
    })
  }

  onUseAnotherAddressHandleCancel = (item) => {
    if (typeof item === "number") {
      const { orderDetailList } = this.state
      let orderDet = { ...orderDetailList }
      orderDet.address = this.state.ddressList[item].recieveAddress
      orderDet.contactName = this.state.ddressList[item].recieveName
      orderDet.contactTel = this.state.ddressList[item].phone
      pageContext.setState({ orderDetailList: orderDet, modifyOrderDetailState: true })
    }
    pageContext.setState({ openState: false })
  }


  inputOrderDetailList = (inputType, index, val) => {
    const { modifyOrderDetailList } = pageContext.state;
    let list = modifyOrderDetailList
    if (inputType == 0) {
      if (list[index].orderQty > 0) {
        list[index].orderQty -= 1
      }
      // else {
      //   list.splice(index, 1)
      // }

    } else if (inputType == 1) {
      list[index].orderQty += 1
    } else if (inputType == 2) {
      list[index].orderQty = val
    }

    this.setState({ modifyOrderDetailList: list, modifyOrderDetailState: true });
  }

  inputOrderDetailRemarkList = (index, e) => {
    const { modifyOrderDetailList } = pageContext.state;
    let list = modifyOrderDetailList
    list[index].detailRemark = e.target.value

    this.setState({ modifyOrderDetailList: list, modifyOrderDetailState: true });
  }


  setOrderRemark = (e) => {
    const { orderDetailList } = pageContext.state;
    orderDetailList.orderRemark = e
    // list[index].detailRemark = e.target.value

    this.setState({ orderDetailList, modifyOrderDetailState: true });
  }

  delectOrderDetailList = (index) => {
    const { modifyOrderDetailList } = pageContext.state;
    let list = modifyOrderDetailList
    list.splice(index, 1)
    this.setState({ modifyOrderDetailList: list, modifyOrderDetailState: true });
    // pageContext.setState({ modifyOrderDetailList: true });
  }

  submitUpdateOrder = (amtValue) => {
    const { modifyOrderDetailList, orderDetailList } = pageContext.state;
    let params = orderDetailList
    params.amtValue = amtValue
    updateOrder(params).then(res => {
      if (res && res.responseCode == "00") {
        toast.success(res.responseMessage);
        pageContext.getMyOrders();
      }
      this.setState({ modifyOrderDetailState: false, orderDetailModal: false });
    })


    // pageContext.setState({ modifyOrderDetailList: true });
  }

  searchOrderList = () => {
    const { pagination } = this.state

    pagination.current = 1
    this.setState({ pagination }, () => { this.getMyOrders() })
  }


  render() {
    const { myOrdersList, pagination, loading, orderDetailList, orderDetailModal,
      orderDetailModalLoading, payQRcodeState, payInfo, modifyOrderDetailState,
      closeModal, symbol, openState, ddressList, defaultDdress, modifyOrderDetailList } = this.state

    const columns = [
      {
        title: intl.get("serialNumber"),
        key: 'No.',
        width: 65,
        render: (text, record, index) => <a>{index + 1}</a>,
      },
      {
        title: intl.get("OrderTime"),
        width: 200,
        dataIndex: 'createDateFortmat',

      },
      {
        title: intl.get("orderNumber"),
        width: 150,
        dataIndex: 'orderNo',
        // render: (item) => <a style={{ color: "blue" }} onClick={() => this.getOrderDetail(item)}>{item.orderNo}</a>,

      },
      {
        title: intl.get("NumberItems"),
        width: 80,
        dataIndex: 'prodQty',

      },
      {
        title: intl.get("orderAmount"),
        width: 110,
        render(item) {
          return "$ " + item.amtValue
        }
      },
      {
        title: intl.get("PaymentMethod"),
        dataIndex: 'payType',
        render(item) {
          return payType[item]
        }
      },
      {
        title: intl.get("PaymentStatus"),
        dataIndex: 'payStatus',
        render(item) {
          return <div style={{ color: item == 0 || item == 2 ? "#EE1D23" : "" }}>{payStatus[item]}</div>
        }
      },
      {
        title: intl.get("OrderStatus"),
        dataIndex: 'sendGoodsStatus',
        render(item) {
          return <div style={{
            color: item == 0 ? "rgba(0,0,0,0.45)"
              : item == 1 ? "#2E3092"
                : item == 3 ? "#EE1D23" : ""
          }}>{deliveryStatus[item]}</div>
        }
      },
      {
        title: intl.get("operation"),
        render(item) {
          return <div className='operation_style'>
            <button
              style={{ display: item.payStatus == 0 && item.sendGoodsStatus !== 3 ? "" : "none" }}
              className='darkerBlue_text payment_input'
              onClick={(e) => { e.stopPropagation(); pageContext.openPayModal(item) }}
            >
              {intl.get("payment")}
            </button>

            <button
              style={{ display: item.payStatus == 0 && item.sendGoodsStatus == 0 ? "" : "none" }}
              onClick={(e) => {
                e.stopPropagation();
                pageContext.delectCancelUnpayorder(item)
              }}
              className='darkerRed_text payment_input'
            >
              {intl.get("cancel")}</button>

          </div >
        }
      }

    ]
    return (
      <>

        <div className='my_orders'>
          <Form
            ref={this.formRef}
            onFinish={this.searchOrderList}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            layout="inline"
          >
            <Form.Item
              label={intl.get("orderNumber")}
              name="orderNo"
            >
              <Input placeholder={intl.get("PleaseEnter")} allowClear onChange={this.onChangeInput} />
            </Form.Item>

            <Form.Item
              label={intl.get("OrderTimeRange")}
              name="createDateFortmat"
            >
              <RangePicker onChange={this.onChangeRangePicker} />
            </Form.Item>
            {/* <Form.Item
                  label="下单时间范围"
                  name="createDateFortmat"
                >
                  <Input placeholder="开始时间" />
                </Form.Item>
                <Form.Item
                  name="createDateFortmatdel"
                >
                  <Input placeholder="结束时间" />
                </Form.Item> */}
            <Form.Item>
              <button className='darkerBlue_text query_input' htmlType="submit"> {intl.get("search")}</button>
              {/* <Button
                type="primary"
                className='darkerBlue_text'
                htmlType="submit"
                size="default"
              >

              </Button> */}
            </Form.Item>
          </Form>

        </div>

        {loading ? <div className='my_orders_spin'><Spin /></div> :
          myOrdersList.length > 0 ?
            <div className='my_orders'>
              <Table
                onRow={(record) => {
                  return {
                    onClick: (event) => { this.getOrderDetail(record) }, // 点击行
                  };
                }}
                id="table"
                scroll={{ y: '100%' }}
                columns={columns}
                pagination={pagination}
                dataSource={myOrdersList}
                onChange={this.onChange}
                loading={loading}
              />
            </div>
            :
            <div className='no_news_yet'>
              <div className='no_news_yet_title'>
                <InfoCircleOutlined style={{ fontSize: '36px' }} />
                <div className='font-size-20'>{intl.get("NoOrdersAvailable")}</div></div>
              <div className='no_news_yet_context'>{intl.get("YouCanViewExistingOrdersHere")}</div>
              <div>
                <a className='registration_successful_backHome'
                  href={`${process.env.PUBLIC_URL}/`}
                >{intl.get("returnToHomepage")}</a>
                <a className='registration_successful_commodityCentre'
                  href={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                >{intl.get('productCenter')}</a>
              </div>
            </div>
        }
        <OrderDetailModal
          orderDetailModal={orderDetailModal}
          loading={orderDetailModalLoading}
          handleCancel={this.handleCancel}
          orderDetailList={orderDetailList}
          modifyOrderDetailList={modifyOrderDetailList}
          modifyOrderDetailState={modifyOrderDetailState}
          submitUpdateOrder={this.submitUpdateOrder}
          payStatus={payStatus}
          payType={payType}
          deliveryStatus={deliveryStatus}
          switchPayQRcodeState={this.switchPayQRcodeState}
          symbol={symbol}
          cancelOrderFun={this.delectCancelUnpayorder}
          onUseAnotherAddressHandle={this.onUseAnotherAddressHandle}
          inputOrderDetailList={this.inputOrderDetailList}
          delectOrderDetailList={this.delectOrderDetailList}
          inputOrderDetailRemarkList={this.inputOrderDetailRemarkList}
          setOrderRemark={this.setOrderRemark}
        />
        <PayModal

          payQRcodeState={payQRcodeState}
          onPayQRcodeCancel={this.onPayQRcodeCancel}
          payInfo={payInfo}
          history={this.props.history}
          cancelOrderFun={this.cancelOrderFun}

        >

        </PayModal>
        <Modal title={intl.get("RetrievingPaymentInformation")} open={closeModal} onOk={this.handleOkClose} onCancel={this.onCancelClose} >
          <p>{intl.get("WouldCancelOrder")}</p>
        </Modal>

        <DeliveryAddressModal
          ref={this.childRef}
          openState={openState}
          ddressList={ddressList}
          defaultDdress={defaultDdress}
          onUseNewAddress={this.onUseNewAddress}
          onUseAnotherAddressHandleCancel={this.onUseAnotherAddressHandleCancel}
        />
      </>

    )
  }
}
