import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { listCarousel, listAnMouth, listSpecProd, myOrders, listCar } from "@/utils/user";
// Import custom components
import ProductsList from "../common/ProductsList";
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";
import {
  putProductInfo, putSpecialOffer,
  putLatestProducts
} from "@/storage/PageParamers";
import { removeToken, putUserName } from "@/storage/LocalParamers";
import { cartNumberDelect, cartNumber } from "@/actions";
import { connect } from "react-redux";
import { Empty } from 'antd';
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";
import "./main.css";
let pageContext
//优惠列表
const listOfOffers = 1;
//新商品列表
const newProductsList = 2
class Kids extends Component {
  constructor(props) {
    super(props);
    loadLocales('layouts')
    this.state = { data: [], listAnMouth: [], refresh: false, listSpecProd: [] };
    pageContext = this
  }
  componentDidMount() {

    this.listCarousel();
    this.listAnMouth();
    this.listSpecProd();
    this.getlistCars();
    this.getMyOrders();
    document
      .getElementById("color")
      .setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color10.css`);
  }
  getMyOrders = () => {
    myOrders().then(res => {
      if (res && res.responseCode !== "00") {
        putUserName(false)
        removeToken()
        this.props.cartNumberDelect();
      }
    })
  }

  listCarousel() {
    let params = {
      // email: "123",
      // password: "12321"
    };
    listCarousel(params).then(res => {
      //接收后台返回的数据
      if (res && res.data) {
        this.setState({ data: res.data });
      }
    });
  }
  listAnMouth() {
    let params = { ShopGoodsCenterSearch: {} };
    listAnMouth(params).then(res => {
      if (res) {
        putLatestProducts(res.records)
        pageContext.setState({ listAnMouth: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
      }
    });
  }

  listSpecProd() {
    let params = {};
    listSpecProd(params).then(res => {
      if (res) {
        putSpecialOffer(res.records)
        pageContext.setState({ listSpecProd: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
      }
    });
  }

  getlistCars = () => {
    listCar().then(res => {
      if (res && res.responseCode == "00") {
        if (res.data.shopCarVOList && res.data.shopCarVOList.length > 0) {
          let shopCarNum = 0
          shopCarNum = res.data.shopCarVOList.length
          // res.data.shopCarVOList.map(res2 => res2.quantity ? shopCarNum += res2.quantity : "")
          this.props.cartNumber(shopCarNum)
        } else {
          this.props.cartNumber(0)
        }
      }
    });
  }
  render() {
    const { data, listAnMouth, listSpecProd } = this.state;
    return (
      <div className="homePage-style" style={{}}>
        {/* <Helmet>
          <title>{intl.get("homePage")}</title>
        </Helmet> */}
        <div className="homePage-layouts"  >
          <HeaderOne logoName={"logo/6.png"} />
          <div >
            <section className="p-0 mainPosition container">
              <Slider className="slide-1 home-slider">
                {data.map(item => (
                  <div key={item.keyId}>
                    <div
                      style={{
                        maxHeight: "450px",
                        backgroundImage: `url(${item.imageUrl}`
                      }}
                      className="home home21 text-center p-right"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="footerPosition">
                {/* <div>
                  <div className="swiper-title">
                    <div id="swiper-title1">{intl.get("fallNewArrivals")}</div>
                    <div id="swiper-title2">{intl.get("BTSStationerySale")}</div>
                  </div>

                  <Link
                    className="btn btn-solid"
                    style={{
                      border: "2px solid #EE1D23",
                      backgroundImage: "linear-gradient(30deg, #EE1D23 50%, transparent 50%)"
                    }}
                    to={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                  >
                    {intl.get("goToPurchase")}
                  </Link>
                </div> */}
              </div>
            </section>

            {listSpecProd.length == 0 && listAnMouth.length == 0 ? <Empty style={{ margin: "20px 0 " }} /> : ""}
            <ProductsList displayType={listOfOffers} listAnMouth={listSpecProd} history={this.props.history} />
            <ProductsList displayType={newProductsList} listAnMouth={listAnMouth} history={this.props.history} />
          </div>

          <div className="push"></div>

        </div>
        <div className="footer">
          <FooterOne />

        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {

};
export default connect(mapStateToProps, { cartNumberDelect, cartNumber })(Kids);
