import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
// import { listShop } from "@/utils/user";
// import custom Components
import { putProductInfo } from '@/storage/PageParamers'
import ProductListing from "./common/product-listing";
import Breadcrumb from "../common/breadcrumb";
import { Tabs, ConfigProvider, Input, Select, Button } from 'antd'
import { listShopCate, listSpecProd } from '@/utils/user'
import './collection-no-sidebar.css'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

let pageContext
const { Search } = Input;
class CollectionNoSideBar extends Component {
  constructor(props) {
    super(props);
    loadLocales('collection')
    this.state = {
      layoutColumns: 3,
      listShop: [],
      refresh: true,
      tabskeys: "first",
      listShopCate: []
      , selectedKeys: null
    };
    pageContext = this

  }

  componentDidMount() {
    pageContext.listShopCate();
  }
  listShopCate() {
    listShopCate().then(res => {
      //接收后台返回的数据
      if (res && res.length > 0) {
        // putProductInfo(res);
        pageContext.setState({ listShopCate: res });
      }
    });
    // let data = await listShop(params);
  }

  LayoutViewClicked(colums) {
    pageContext.setState({
      layoutColumns: colums
    });
  }
  onRefresh = () => {
    pageContext.setState({ refresh: !pageContext.state.refresh });
  };

  onSortBy = sort => {
    // 通过子组件的实例调用组组件中的方法
    pageContext.childRef.listShop();
    pageContext.setState({
      sort: sort,
      refresh: !pageContext.state.refresh
    });
  };

  handleChildEvent = ref => {
    // 将子组件的实例存到 pageContext.childRef 中, 这样整个父组件就能拿到
    pageContext.childRef = ref;
  };
  onChange(keys, event) {
    const { listShopCate } = pageContext.state
    let newKeyId = keys
    for (const key in listShopCate) {
      if (listShopCate[key].key == keys && keys != "first") {
        newKeyId = key
      }
    }
    pageContext.childRef.switchTab(newKeyId)
    pageContext.setState({ tabskeys: newKeyId })
  };
  handleChange = (value) => {
    pageContext.childRef.listShop({ sortBy: value });
  };

  onSearch = (e) => {
    this.setState({ onSearch: e.target.value });
    // const { onSearchProductCode } = this.state
    // let searchObj = {
    //   onSearch: value
    // }
    // if (onSearchProductCode) {
    //   searchObj.onSearchProductCode = onSearchProductCode
    // }
    // pageContext.childRef.listShop(searchObj);
  }

  onSearchProductCode = (e) => {
    this.setState({ onSearchProductCode: e.target.value });

  }

  onSearchProduct = () => {
    const { onSearch, onSearchProductCode } = this.state
    let searchObj = {
      onSearch,
      onSearchProductCode,
      pages: 1,
      current: "1"
    }

    pageContext.childRef.listShop(searchObj);
  }

  onSearchReset = () => {
    this.setState({ onSearchProductCode: null, onSearch: null });
  }

  onKeyPressSearch = (e) => {
    if (e.key === 'Enter') {
      // 在这里处理回车键事件
      const { onSearch, onSearchProductCode } = this.state
      let searchObj = {
        onSearch,
        onSearchProductCode,
        pages: 1,
        current: "1"
      }

      pageContext.childRef.listShop(searchObj);
    }
  }
  onTreeSelect = (newSelectedKeys) => {
    const { selectedKeys, listShopCate } = this.state
    if (newSelectedKeys !== selectedKeys) {
      let newItem = listShopCate.filter(item => item.key === newSelectedKeys)
      let cateCode
      if (newItem.length > 0) {
        cateCode = newItem[0].cateCode
      } else {
        cateCode = false
      }

      this.setState({ selectedKeys, cateCode }, () => { pageContext.childRef.onTreeSelectCateCode(cateCode) });
    }

  }

  listSpecProd() {
    let params = {};
    listSpecProd(params).then(res => {
      if (res) {
        pageContext.setState({ listSpecProd: res.records }, () => { pageContext.setState({ refresh: !pageContext.state.refresh }) });
        putProductInfo(res.records);
      }
    });
  }

  render() {
    const { tabskeys, listShopCate,
      onSearch,
      onSearchProductCode } = pageContext.state

    const items = [
      {
        key: "first",
        label: <div style={{ padding: "9px 30px", fontSize: "17px" }}>{intl.get("allProducts")}</div>,
      }
    ]

    listShopCate.map(item => items.push({
      key: item.key,
      label: <div style={{ padding: "9px 30px", fontSize: "17px" }}>{item.title}</div>,
    }))
    return (
      <div>
        <Breadcrumb title={intl.get('productCenter')} />

        {/*Section Start*/}
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#EE1D23',
                  },
                }}
              >
                <Tabs
                  defaultActiveKey="first"
                  onChange={this.onTreeSelect}
                  items={items}

                  size="large"
                  onTabClick={pageContext.onChange} />
                <div className="search_title">
                  <div onKeyPress={this.onKeyPressSearch}>
                    <Input allowClear onChange={this.onSearch} className="search_input"
                      placeholder={intl.get("productKeywords")}
                      value={onSearch}
                      status="warning"
                      size="large" />

                    <Input className="search_input" allowClear onChange={this.onSearchProductCode}
                      placeholder={intl.get("ProductCode")}
                      value={onSearchProductCode}
                      status="warning"
                      size="large" />
                    <Button style={{ marginRight: "10px" }} onClick={this.onSearchProduct}
                      type="primary" size="large">
                      {intl.get("query")}

                    </Button>
                    <Button size="large" onClick={this.onSearchReset}>{intl.get("reset")}</Button>
                  </div>

                  <div> {intl.get("sortBy")}: <Select
                    size="large"
                    defaultValue="1"
                    style={{
                      width: 214,
                    }}
                    onChange={pageContext.handleChange}
                    options={[
                      {
                        value: '1',
                        label: intl.get("HightoLowSales"),
                      },
                      {
                        value: '2',
                        label: intl.get("LowtoHighSales"),
                      },
                      {
                        value: '3',
                        label: intl.get("LowtoHighPrice"),
                      },
                      {
                        value: '4',
                        label: intl.get("HightoLowPrice"),
                      },
                    ]}
                  /></div>
                </div>
                {tabskeys == "first" ?
                  <div>
                    <ProductListing
                      sort={
                        !pageContext.state.sort ? "asc" : pageContext.state.sort
                      }
                      onChildEvent={pageContext.handleChildEvent}
                      listShopCate={listShopCate}
                      tabskeys={tabskeys}
                      onRefresh={pageContext.onRefresh}
                      colSize={pageContext.state.layoutColumns}
                      propsState={pageContext.state}
                    />
                  </div>
                  :
                  <div>

                    <ProductListing
                      sort={
                        !pageContext.state.sort ? "asc" : pageContext.state.sort
                      }
                      onChildEvent={pageContext.handleChildEvent}
                      listShopCate={listShopCate}
                      tabskeys={tabskeys}
                      onRefresh={pageContext.onRefresh}
                      colSize={pageContext.state.layoutColumns}
                      cateCode={pageContext.state.cateCode}
                      propsState={pageContext.state}
                    />
                  </div>}

              </ConfigProvider>

            </div>
          </div>
        </section>

        {/*Section End*/}
      </div>
    );
  }
}

export default CollectionNoSideBar;
